.filter-box{
    display: flex;
    margin: $m-size 0;

    span {
        display: flex;
        line-height: 4.6rem;
        padding-right: 1.2rem;
    }
}
