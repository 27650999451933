* {
    box-sizing: border-box;
    //font-family: 'Permanent Marker', cursive;
    font-family: 'Black Ops One', cursive;
    
}

html {
    font-size: 62.5%;
}

body {
    color: $dark-grey;
    font-family: Helvetica, Arial, sans-serif;
    font-size: $m-size;
    line-height: 1.6;
    margin: 0;
}

a {
    cursor: pointer;
}

button {
    cursor: pointer;
}

button:disabled {
    cursor: default;
}


.is-active {
    font-weight: bold;
    color: chartreuse;
}