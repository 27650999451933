// Colors
$blue: #1C88BF;
$dark-blue: #263F73;
$dark-grey: #333333;  
$dark-orange: #FF8C00;
$grey: #666666;
$off-white: #F7F7F7;
$light-blue: #ADD8E6;

// Font Size
$font-size-xxl: 3.2rem;
$font-size-xl: 2.4rem;
$font-size-large: 1.8rem;
$font-size-small: 1.4rem;

// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;

$desktop-breakpoint: 45rem;

