.button {
    background-color: $blue;
    border: none;
    color: white;
    display:  inline-block;
    font-weight: 100;
    font-size: $font-size-large;
    line-height: 1;
    margin-right: $s-size;
    padding: $s-size;
    text-decoration: none;
}

.button_Link {
    background: none;
}

.button_Secondary{
    background: #888888;
}

.button_Red {
    background: #990000;
}